import React from "react";

function Title({ title }) {
  return (
    <div className="text-title text-center pb-5 md:pb-9 flex flex-col gap-6">
      <div className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary-green to-primary-light pb-0 text-2xl md:text-[42px] uppercase">
        {title ? title : ""}
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-row gap-2 mb-3 items-center">
          <div className="w-24 h-1 bg-primary-deep"></div>
          <div className="w-3 h-3 rounded-full bg-primary-deep"></div>
          <div className="w-24 h-1 bg-primary-deep"></div>
        </div>
      </div>
    </div>
  );
}

export default Title;
