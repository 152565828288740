import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import appConfig from "../../../constants/configurations";
import { useQuery } from "react-query";
import { ThreeDots } from "react-loader-spinner";
import Pagelayout from "../../../layouts/Pagelayout";

function RealisationDetails() {
  const { id } = useParams();

  const { isLoading, data, isSuccess } = useQuery(
    "realisationDetails",
    async () => {
      const { data } = await axios.get(
        `${appConfig.API_URL}/realisation/list/${id}`
      );
      return data.data;
    }
  );

  useEffect(() => {
    document.getElementById("content").innerHTML = isSuccess
      ? data.content
      : "";
  }, [data]);

  return (
    <Pagelayout
      title={isSuccess && data.title}
      description="Nous pouvons vous aider à réaliser votre projet, laisser nous juste un message"
    >
      <div className="container_fluid">
        {isLoading ? (
          <div className="flex w-full justify-center items-center py-12">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <div id="content" className="hidden"></div>
          </div>
        ) : isSuccess ? (
          <div className="mt- py-20 flex flex-col gap-4">
            <div className="text-2xl md:text-4xl font-bold">
              <span>Projet:</span>{" "}
              <span className="text-primary-green">
                {data.title && data.title}
              </span>
            </div>
            <div className="flex gap-2 items-center uppercase text-sm font-semibold">
              <span>STATUT </span>
              <span className="w-[1.4px]">:</span>
              <span>{data.status === 1 ? "terminé" : "Encours"}</span>
            </div>
            <div>{data.description}</div>
            <div
              id="content"
              className="text-justify flex flex-col gap-2 mt-6"
            ></div>
          </div>
        ) : null}
      </div>
    </Pagelayout>
  );
}

export default RealisationDetails;
