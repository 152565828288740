import React from "react";
import styles from "./service.module.css";
import ServiceCard from "../../../components/Cards/ServiceCard";
import Title from "../../../components/Title";
import IMAGES from "../../../constants/images";

function Services() {
  const services = [
    {
      icon: IMAGES.ACCOMP,
      label: "L'accompagnement",
      description:
        "dans les collectes des données pour les études de marchés, la rédaction de leur plan d’affaire.",
    },
    {
      icon: IMAGES.COLLECT,
      label: "La collecte et analyse",
      description:
        "des données agropastorales et socio-économiques et démographiques.",
    },
    {
      icon: IMAGES.CONCEPTION,
      label: "Conception et réalisation d’enquêtes agricoles",
      description: "réalisation des plans d'échantillonages pour les enquêtes.",
    },
    {
      icon: IMAGES.REALISATION,
      label:
        "Conception et réalisation d’enquêtes socio-économique pour des études de planification urbaine, de marchés",
      description:
        "Conception et réalisation d’enquêtes socio-économique pour des études de planification urbaine, de marchés etc.",
    },
    {
      icon: IMAGES.PROJET,
      label: "le montage des projets",
      description: "Montage des projets issus des problèmes de population.",
    },
    {
      icon: IMAGES.FORMATION,
      label: "La formation des acteurs",
      description:
        "Formation des acteurs sociaux en analyse des données et utilisation des outils statistiques.",
    },
  ];
  return (
    <div className={`${styles.services} flex justify-center items-center`}>
      <div className="container_fluid py-24">
        <Title title="Nos Domaines D'Expertise" />
        <div className="flex flex-col md:flex-row overflow-hidden gap-6">
          <div className="w-full">
            <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {services.length &&
                services.map((item) => {
                  return (
                    <ServiceCard
                      key={item.label}
                      icon={item.icon}
                      label={item.label}
                      description={item.description}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
