import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "./../../../constants/images";
import styles from "./navbar.module.css";

function Landingnavbar() {
  const navigate = useNavigate();

  const [navbar, setNavbar] = useState(false);

  // For Hamburger
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const closeMobileMenu = () => setOpen(false);
  const defaultBtn =
    "px-6 bg-white hover:bg-slate-300 py-3 rounded-full text-gray-700 animation duration-300 hover:translate-y-1";

  // Scroll Effect
  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    <div
      className={`${styles.navbar} ${
        navbar ? styles.navbar_change : "bg-transparent"
      }  drop-shadow-md flex flex-col justify-center items-center`}
    >
      <div className="container_fluid flex flex-row justify-between items-center pt-2 pb-2">
        <div className="brand w-1/2 flex flex-row items-center md:gap-1 md:w-1/4">
          <Link
            to="/"
            className="logo w-14 h-14 bg-white flex items-center rounded-md p-1"
          >
            <img src={IMAGES.LOGO} alt="Logo" className=" object-scale-down" />
          </Link>
          <div
            className={`font-bold text-4xl invisible md:visible  cursor-pointer  ${
              navbar ? "text-primary-light" : "text-white"
            }`}
          >
            SC<span className="text-primary-green">SM</span>
          </div>
        </div>

        {!open ? (
          <div
            className={`md:invisible ${styles.hamburger} bg-white rounded-full shadow overflow-hidden`}
            onClick={handleOpen}
          >
            <div className="rounded-full bg-white overflow-hidden p-3 cursor-pointer shadow">
              <FaBars className="text-primary-deep" />
            </div>
          </div>
        ) : (
          <div
            className={`md:invisible ${styles.hamburger} bg-white rounded-full shadow overflow-hidden`}
            onClick={closeMobileMenu}
          >
            <div className="rounded-full bg-white p-3 cursor-pointer shadow overflow-hidden">
              <FaTimes className="text-primary-deep" />
            </div>
          </div>
        )}

        <div
          className={`flex flex-row md:w-3/4 justify-between hiden md:inline-flex`}
        >
          <div className="flex flex-col justify-center">
            <ul
              className={` ${
                open
                  ? styles.responsive_navbar
                  : styles.nav_items +
                    " " +
                    "list-none invisible md:inline-flex md:-ml-20 md:visible flex items-center gap-8"
              }`}
            >
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/missions">Missions</Link>
              </li>
              <li>
                <Link to="/realisations">Réalisations</Link>
              </li>
              <li>
                <Link to="/actualite">Actualités</Link>
              </li>
              <li>
                <Link to="/carriere">Carrière</Link>
              </li>
            </ul>
          </div>

          <div
            className={`${styles.contact_buttons} gap-3 flex md:inline-flex justify-end items-right`}
          >
            <button onClick={() =>window.open('https://test.scsmaubmar.org','_blank')} className={navbar ? "main_button" : defaultBtn}>
              Connexion
            </button>
            <button
              onClick={() => navigate("/contact")}
              className={navbar ? "main_button" : defaultBtn}
            >
              Nous Contactez
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingnavbar;
