import axios from "axios";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import appConfig from "../../../constants/configurations";
import Pagelayout from "../../../layouts/Pagelayout";

function ActualiteDetails() {
  const { id } = useParams();
  const [nid, setNid] = useState(id) 

  const { isLoading, data, isSuccess, refetch } = useQuery("actualiteData", async () => {
    const { data } = await axios.get(
      `${appConfig.API_URL}/news/list/${nid}`
    );
    return data.data;
  });

  const otherNews = useQuery(
    "newsData",
    async () => {
      const { data } = await axios.get(`${appConfig.API_URL}/news/list`);
      return data.data;
    }
  );

  useEffect(() => {
    document.getElementById("content").innerHTML = isSuccess
      ? data.content
      : "";
  }, [data]);

  useEffect(()=>{
    refetch()
  },[nid])

  return (
    <Pagelayout
      title={isSuccess && data?.title}
      // title={isSuccess && data.title}
      description="Nous impactons dans différents sphéres"
    >
      <div className="container_fluid">
        {isLoading ? (
          <div className="flex w-full justify-center items-center py-12">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <div id="content" className="hidden"></div>
          </div>
        ) : isSuccess ? (
          <div className="py-20 flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-4/6 flex flex-col gap-2">
              <div className="h-[70vh] w-full">
              <Swiper
                modules={[Pagination, Navigation]}
                spaceBetween={15}
                slidesPerView={1}
                navigation={true}
                pagination={true}
                cssMode={true}
                className="h-full rounded-md overflow-hidden"
              >
                {
                  data?.images.split("|").map((item, index)=>{
                    return <SwiperSlide key={index}><img
                    src={`${appConfig.API_HOST}uploads/${item}`}
                    alt="News Image"
                    className="h-full w-full object-fit"
                  /></SwiperSlide>
                  })
                }
              </Swiper>
                
              </div>
              <span className="text-2xl md:text-4xl font-bold mt-6 relative">
                Evénement: {data.title && data?.title}
              </span>
              <div
                id="content"
                className="text-justify flex flex-col gap-2"
              ></div>
            </div>
            <div className="w-full md:w-2/6 px-4">
              <div className="border-[1px] border-gray-300 p-4 pb-8 rounded-md">
                <h2 className="font-semibold mb-4">Autres Evénements</h2>
                <div className="flex flex-col gap-5">
                  {otherNews.isSuccess && otherNews.data.slice(0, 3).map((item) => {
                    return (
                      <Link
                        to={`/actualite/${item.id}`}
                        onClick={()=>setNid(item.id)}
                        key={item}
                        className="shadow-md rounded-sm overflow-hidden cursor-pointer"
                      >
                        <div className="relative h-44 overflow-hidden">
                          <img
                            src={`${appConfig.API_HOST}uploads/${item?.images.split("|")[0]}`}
                            alt="Actualités"
                            className=" object-scale-down animation duration-300 hover:scale-110"
                          />
                          <span className="absolute top-2 right-2 bg-primary-green/90 text-white p-2 px-3 text-[10px] rounded-full">
                            Aujourd'hui
                          </span>
                        </div>
                        <div className="p-2 flex flex-col gap-2 mb-2 px-3">
                          <div className="flex flex-col">
                            <span className="text-base font-medium">
                              {item?.title}
                            </span>
                            <span className="text-sm">24-06-2023</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="text-sm">10 vue(s)</span>
                            <Link
                              to={`/actualite/${item.id}`}
                              className="text-sm text-primary-green hover:underline"
                            >
                              Consulter
                            </Link>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Pagelayout>
  );
}

export default ActualiteDetails;
