import React from "react";
import { BiSmile } from "react-icons/bi";

function StatisticsCard({ icon, count, label }) {
  return (
    <div className="pt-8 pl-7 pb-8 flex flex-row space-x-4 items-center top-shadow rounded-md bg-white md:w-2/4 sm:w-4/4">
      <div>{icon ? icon : <BiSmile size={50} color="#4154f1" />}</div>
      <div className="flex flex-col">
        <span className="text-primary-deep font-medium text-4xl">
          {count ? count : 0}
        </span>
        <span>{label ? label : ""}</span>
      </div>
    </div>
  );
}

export default StatisticsCard;
