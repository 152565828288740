import React from "react";
import { BsCheck2Circle } from "react-icons/bs";

function FeatureCard({ label, delay }) {
  return (
    <div
      className="shadow bg-white p-4 rounded-md flex items-center space-x-4 mb-4"
      data-aos="zoom-out"
      data-aos-delay={delay}
    >
      <div className="bg-milky-text hover:bg-primary-deep transition ease-in-out duration-300 rounded-md p-2">
        <BsCheck2Circle size={30} className="hover:text-white" />
      </div>
      <span className="font-medium text-[18px] ">{label ? label : ""}</span>
    </div>
  );
}

export default FeatureCard;
