import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Landingaboutus from "./pages/landing/Landingaboutus";
import Landingactualite from "./pages/landing/Landingactualite";
import Landingcarriere from "./pages/landing/Landingcarriere";
import Landingcontact from "./pages/landing/Landingcontact";
import Landingmissions from "./pages/landing/Landingmissions";
import Landingpage from "./pages/landing/Landingpage";
import Landingrealisations from "./pages/landing/Landingrealisations";
import Landingservices from "./pages/landing/Landingservices";
import Notfound from "./pages/landing/Notfound";
import ActualiteDetails from "./pages/landing/actualite/ActualiteDetails";
import CarriereDetails from "./pages/landing/carriere/CarriereDetails";
import RealisationDetails from "./pages/landing/realisations/RealisationDetails";

function App() {
  useEffect(() => {
    Aos.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/about" element={<Landingaboutus />} />
        <Route path="/missions" element={<Landingmissions />} />
        <Route path="/services" element={<Landingservices />} />
        <Route path="/carriere" element={<Landingcarriere />} />
        <Route path="/carriere/:id" element={<CarriereDetails />} />
        <Route path="/realisations" element={<Landingrealisations />} />
        <Route path="/realisation/:id" element={<RealisationDetails />} />
        <Route path="/actualite" element={<Landingactualite />} />
        <Route path="/actualite/:id" element={<ActualiteDetails />} />
        <Route path="/contact" element={<Landingcontact />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}

export default App;
