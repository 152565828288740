import React from "react";
import Landinglayout from "../../layouts/Landinglayout";
import WhyUs from "./whyus/WhyUs";
import Clients from "./clients/Clients";
import Feature from "./features/Feature";
import HeroSection from "./hero_section/HeroSection";
import Services from "./services/Services";
import Statistics from "./statistics/Statistics";
import Contact from "./contact/Contact";
import Actualite from "./actualite/Actualite";

// console.log(`${process.env.REACT_APP_FRONTEND}`);
function Landingpage() {
  return (
    <Landinglayout>
      <HeroSection />
      <Statistics />
      <WhyUs />
      <Services />
      <Feature />
      <Clients />
      <Actualite />
      <Contact />
    </Landinglayout>
  );
}

export default Landingpage;
