import React from "react";
import styles from "./feature.module.css";
import FeatureCard from "../../../components/Cards/FeatureCard";
import Title from "../../../components/Title";

function Feature() {
  const data = [
    {
      label: "Focalisé sur le détail",
      delay: "50",
    },
    {
      label: "Un travail pointu et précis",
      delay: "100",
    },
    {
      label: "Utilisations de techniques modernes",
      delay: "150",
    },
    {
      label: "Recherche Intensive",
      delay: "200",
    },
    {
      label: "Suivie constant même après livraison",
      delay: "250",
    },
    {
      label: "Diversité dans différents domaines",
      delay: "300",
    },
    {
      label: "Expérience prouvé dans le domaine",
      delay: "350",
    },

    {
      label: "Bonne pratique de collecte et analyse",
      delay: "400",
    },
    {
      label: "Un service client à votre disponibilité",
      delay: "450",
    },
    {
      label: "Recherche de la satisfaction client",
      delay: "500",
    },
  ];
  return (
    <div
      className={`${styles.features} flex justify-center items-center bg-primary-dark py-20`}
    >
      <div className="container_fluid flex flex-col">
        <Title title="ce qui nous rend différents et les meilleurs" />
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full overflow-hidden">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-4">
              {data.length &&
                data.map((item) => {
                  return <FeatureCard label={item.label} delay={item.delay} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
