import axios from "axios";
import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import appConfig from "../../../constants/configurations";
import Pagelayout from "../../../layouts/Pagelayout";

function CarriereDetails() {
  const { id } = useParams();

  const { isLoading, data, isSuccess } = useQuery("carriereData", async () => {
    const { data } = await axios.get(
      `${appConfig.API_URL}/carriere/list/${id}`
    );
    return data.data;
  });

  useEffect(() => {
    document.getElementById("content").innerHTML = isSuccess
      ? data?.content
      : "";
  }, [data]);

  return (
    <Pagelayout
      title={isSuccess && data.title}
      description="Envoyer votre Démande à work@scsmaubmar.org"
    >
      <div className="container_fluid">
        {isLoading ? (
          <div className="flex w-full justify-center items-center py-12">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <div id="content" className="hidden"></div>
          </div>
        ) : isSuccess ? (
          <div className="mt- py-20 flex flex-col gap-4">
            <span className="text-2xl md:text-4xl font-bold">
              Poste: {data.title && data.title}
            </span>
            <div
              id="content"
              className="text-justify flex flex-col gap-2"
            ></div>
          </div>
        ) : null}
      </div>
    </Pagelayout>
  );
}

export default CarriereDetails;
