import React, { useEffect } from "react";
import { FcBriefcase } from "react-icons/fc";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Title from "../../../components/Title";
import appConfig from "../../../constants/configurations";
import axiosInstance from "../../../utils/axiosInstance";

function Realisations() {
  const { isLoading, data, refetch, isSuccess } = useQuery(
    "realisationData",
    async () => {
      const { data } = await axiosInstance.get(`${appConfig.API_URL}/realisation/list`);
      return data.data;
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container_fluid py-20">
      {isLoading ? (
        <div className="flex w-full justify-center items-center py-12">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : isSuccess && data.length > 0 ? (
        <div>
          <Title title="Nos Actions Parle pour nous" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data &&
              data.slice(0, 6).map((item) => {
                return (
                  <Link
                    key={item.id}
                    to={`/realisation/${item.id}`}
                    className="border-[1px] border-gray-200 pl-1 bg-primary-green animation duration-300 hover:translate-x-2"
                  >
                    <div className="h-full w-full flex flex-col gap-3 bg-white px-3 py-6">
                      <div className="flex gap-2 items-center uppercase text-sm font-semibold">
                        <span>PROJET </span>
                        <span className="w-[1.4px]">:</span>
                        <span>{item.status === 1 ? "terminé" : "Encours"}</span>
                      </div>
                      <h2 className="text-[22px] font-bold">{item.title}</h2>
                      <span className="text-sm mt-16">
                        Du{" "}
                        <span className="font-medium">{item.start_date}</span>{" "}
                        au <span className="font-medium">{item.end_date}</span>
                      </span>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col">
          <div>
            <FcBriefcase size={70} />
          </div>
          <span className="text-xl md:text-2xl text-center">
            Occune Réalisation pour le moment
          </span>
        </div>
      )}
    </div>
  );
}
export default Realisations;
