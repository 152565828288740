import React from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Statistics from "./statistics/Statistics";
import IMAGES from "../../constants/images";

function Landingaboutus() {
  return (
    <Pagelayout
      title="Discover Who We Are"
      description="We exist to Offer creative and Innovative Solutions"
    >
      <div className="container_fluid">
        <div className="flex flex-col md:flex-row w-full section">
          <div
            className="w-full md:w-1/2 flex flex-col gap-8 pr-4 justify-center"
            style={{ overflowX: "hidden" }}
          >
            <div>
              <h2
                className="text-2xl text-primary-deep font-bold"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                A CREATIVE TEAM
              </h2>
              <p>
                We are group of experience developer with over Five Years of
                Experience in the field Helping Businesses Of All Sizes Grow
                Rapidly
              </p>
            </div>

            <div>
              <h2
                className="text-2xl font-bold text-primary-deep"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                OUR VISION
              </h2>
              <p>
                Building up a dynamic group of youg visionary and transformed
                youths that will leave a mark on the wall of the world through
                the use of their IT Skills
              </p>
            </div>

            <div>
              <h2
                className="text-2xl font-bold text-primary-deep"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                OUR MISSION
              </h2>
              <p>
                We are at the service of Individuals, Small and Large sized
                Firms to help them Digitalizing their Businesses or
                automating/improving/maintenaning their daily-based operations
                or existing solutions through audit, debugging and testing
              </p>
            </div>

            <div>
              <h2
                className="text-2xl font-bold text-primary-deep"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                OUR PURPOSE
              </h2>
              <p>
                We are group of experience developer with over Five Years of
                Experience in the field Helping Businesses Of All Sizes Grow
                Rapidly
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2 flex justify-center items-center text-center">
            <img src={IMAGES.AGILITY} alt="" />
          </div>
        </div>
        <Statistics />
      </div>
    </Pagelayout>
  );
}

export default Landingaboutus;
