import React from "react";
import { useNavigate } from "react-router-dom";

function Notfound() {
  const navigate = useNavigate();

  return (
    <div className=" h-screen w-full bg-primary-green flex items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <h2 className="text-white text-2xl md:text-6xl font-semibold text-center">
          Page Introuvable 🚷
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="bg-white px-4 py-3 rounded-full animation duration-300 hover:scale-110 cursor-pointer w-fit text-sm"
        >
          Retour à l'accueil
        </button>
      </div>
    </div>
  );
}

export default Notfound;
