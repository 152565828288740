import React from "react";
import styles from "./style.module.css";

function ServiceCard({ icon, label, description }) {
  return (
    <div
      className={`${styles.service_card} flex flex-col gap-2 shadow p-4 rounded-md hover:bg-primary-green hover:text-white cursor-pointer`}
    >
      <div className="flex flex-col gap-4 items-center hover:text-white">
        <div className="w-32">
          <img src={icon} className="w-full" alt="Services" />
        </div>
        <span className="text-gray-700 hover:text-white font-semibold text-[18px] text-center">
          {label ? label : ""}
        </span>
      </div>
      <span className="text-[14px] text-center">
        {description ? description : ""}
      </span>
    </div>
  );
}

export default ServiceCard;
