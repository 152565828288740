import React from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Services from "./services/Services";

function Landingservices() {
  return (
    <Pagelayout
      title="Découvrez Nos Services"
      description="Nous sommes disponibles pour vous servir"
    >
      <Services />
    </Pagelayout>
  );
}

export default Landingservices;
