import React from "react";
import styles from "./Hero_section.module.css";
import { useNavigate } from "react-router-dom";
function HeroSection() {
  const navigate = useNavigate();

  const btn =
    "px-6 bg-white hover:bg-slate-300 py-3 rounded-full text-gray-700 font-medium animation duration-300 hover:translate-y-1 text-[10px] md:text-[16px]";
  return (
    <div
      className={`${styles.hero_section} h-[80vh] md:h-[100vh] bg-red-500 flex justify-center items-center`}
    >
      <svg
        className={styles.wave}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 312"
      >
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,192L60,181.3C120,171,240,149,360,133.3C480,117,600,107,720,106.7C840,107,960,117,1080,122.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>

      <div className="container_fluid flex flex-col md:flex-row justify-between">
        <div className="flex flex-col gap-3 justify-center md:mb-12 lg:px-24">
          <h1
            className="font-semibold text-2xl md:text-5xl md:mb-6 text-white leading-normal text-center md:px-24"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            Statistic Consulting Survey and Multiservice Sarl.
          </h1>
          <span
            className="text-[15px] text-slate-200 font-medium text-center px-4 md:px-48"
            data-aos="zoom-out"
            data-aos-duration="1500"
          >
            SCSM est un bureau d'études, de consultation, de sondage, de
            collecte et d’analyse des données{" "}
            <strong className="text-green-400">
              agro-pastorales, urbaines, de population
            </strong>
            , <strong className="text-green-400"></strong> et biens d'autres.
          </span>

          <div
            className="mt-4 flex gap-4 md:gap-6 items-center justify-center"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <button
              className="main_button text-[10px] md:text-[16px]"
              onClick={() => navigate("/contact")}
            >
              Nous Contactez
            </button>
            <div>
              <button className={btn}>En savoir plus</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
